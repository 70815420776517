@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
html {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
}
*,
*::after *::before {
  box-sizing: inherit;
}
body {
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
.gmail-page-body {
  background-color: #eee;
}
.banner-title-section {
  padding: 30px 0 0 0;
}
.gallery-wrapper {
  width: 18%;
  margin: 15px 0.8%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
.gallery-wrapper:hover .gallery-content {
  cursor: pointer;
  display: block;
}
.main-footer-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.main-footer-form {
  background-image: url(../images/fields-background.png);
  border-radius: 20px;
  width: 100%;
  min-height: 250px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center center !important;
}

.available-places {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-style: italic !important;
}

.accordion-button::after {
  background-image: url(../images/plus_minuse.svg) !important;
  background-repeat: no-repeat;
  background-position: right center;
  transform: inherit !important;
}
.accordion-button.collapsed::after {
  background-image: url(../images/plus_square.svg) !important;
  background-repeat: no-repeat;
  background-position: right center;
  transform: inherit !important;
}
.header-items {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: end;
}
.btLogo {
  cursor: pointer;
  width: auto;
  object-fit: cover;
  height: 45px;
}
.hideAll {
  display: none;
}
.showAll {
  display: flex;
}
.caseBox {
  border-radius: 25px;
  overflow: hidden;
  height: 290px;
}
.caseBox .case-img {
  width: 100%;
  object-fit: cover;
  min-height: 290px;
}
.case-gallery-content {
  width: 100%;
}
.case-warpper {
  width: 20%;
  margin: 20px;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #c2114b;
  border-color: #c2114b;
}
.address-textarea {
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  padding: 10px;
  outline: none !important;
}
.bi-trash {
  padding-left: 8px;
  cursor: pointer;
}
.form-control,
.form-select {
  height: 45px !important;
}
.kids-form-section .MuiInputBase-input,
.add-skill-wrapper .MuiInputBase-input {
  height: 45px;
  padding: 0 15px;
  font-size: 15px;
}
.add-skill-wrapper .MuiInputBase-fullWidth {
  padding: 0;
  height: 45px;
}
.add-skill-wrapper .MuiFormLabel-root {
  line-height: 12px;
  font-size: 15px;
  min-height: 20px;
}
.css-13cymwt-control {
  height: 45px !important;
}
.select-text {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
  cursor: auto;
}
.select-text-only {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 0;
  cursor: auto;
}
.absolute-input {
  height: 55px;
  border: 0;
  outline: 0;
}
.select-wrapper {
  display: flex;
  align-items: center;
  width: 600px;
  height: 55px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
  padding: 20px;
  margin: 8px 0;
}
.email-input {
  justify-content: unset;
}
label {
  font-size: 14px;
  color: #afafaf;
  font-weight: 600;
}
.dialog-footer {
  border-top: 1px solid #adadad;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;
}
img.iconMenu {
  width: 21px;
  height: auto;
  object-fit: cover;
  margin-right: 11px;
}
img.iconMenu.hover {
  display: none;
}
.brand-menu-wrapper:hover img.iconMenu.normal,
.sidemenu-active img.iconMenu.normal {
  display: none;
}
.brand-menu-wrapper:hover img.iconMenu.hover,
.sidemenu-active img.iconMenu.hover {
  display: inline-block;
}
.dialog-body {
  overflow: scroll;
  overflow: auto;
}
.modal-logo {
  padding-right: 30px;
  cursor: pointer;
  border-right: 1px solid #d9d9d9;
  height: 50px;
  object-fit: cover;
}
.step-wrapper {
  display: flex;
  align-items: center;
}
.user-details {
  display: flex;
  padding: 0;
  margin: 2px 0;
}
.location-wrapper {
  display: flex;
  padding-right: 10px;
  align-items: center;
}
.location-wrapper > img {
  width: 15px;
  height: 15px;
}
.user-details .location-wrapper > img {
  filter: brightness(0) invert(1);
}
.user-icon {
  width: 22px;
  height: 23px;
}
.more-text {
  color: #c2114b !important;
}
.age-button {
  border-radius: 161px !important;
  border: 1px solid #000;
  background: #000;
}
.password-wrapper {
  justify-content: space-between;
}
.joinnow-text {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}
.joinnow-text:hover {
  color: #fff;
}
.brand-section > .brand-title {
  color: #fff;
  font-size: 53px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 0 0 7px !important;
  font-family: sans-serif;
}
.brand-section > p > span {
  color: #c2114b;
}
.brand-section > .brand-description {
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-family: sans-serif;
}
.banner-content .col-lg-6 {
  justify-content: center;
  display: flex;
}
.brand-options {
  width: 100%;
  min-height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  align-items: center;
  padding: 0 0 20px;
  max-width: 485px;
}
.join-talent-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
}
.hire-talent-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input[type="checkbox"]:checked {
  accent-color: #c2114b !important;
  color: #fff !important;
}
.box {
  max-width: 288px;
  width: 100%;
}
.box .search-box {
  position: relative;
  height: 50px;
  max-width: 50px;
  margin: 0 0 0 auto;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.25);
  border-radius: 25px;
  transition: all 0.3s ease;
}
#check:checked ~ .search-box {
  max-width: 380px;
}
.search-box input {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 25px;
  background: #fff;
  outline: 0;
  border: 0;
  padding-left: 20px;
  font-size: 18px;
}
.search-box .icon {
  position: absolute;
  right: -2px;
  top: 0;
  width: 50px;
  background: #fff;
  height: 100%;
  text-align: center;
  line-height: 50px;
  color: #c2114b;
  font-size: 20px;
  border-radius: 25px;
  cursor: pointer;
}
#check:checked ~ .search-box .icon {
  background: #c2114b;
  color: #fff;
  width: 60px;
  border-radius: 0 25px 25px 0;
}
#check {
  display: none;
}
a.active {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #c2114b !important;
  font-size: 15px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
  color: #000;
  transition: 0.5s;
}
.header-functions div.gridLogo {
  margin-right: 0;
}
.rating {
  position: absolute;
  background-color: #fff;
  border-radius: 30px;
  padding: 5px;
  bottom: 10px;
  top: inherit;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 65px;
  height: 20px;
}
.cursor {
  cursor: pointer;
}
.rating > img {
  width: 8px;
  height: 8px;
  margin: 0 2px;
}
.card-title {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px 0 5px;
  text-align: center;
  margin: 0 !important;
}
.modal-wrapper {
  width: 100% !important;
  height: 100% !important;
}
.register-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.absolute-input {
  height: 55px;
  border: 0;
  outline: 0;
}
.input-icons {
  width: 22px;
  height: 22px;
}
.signup-options div button {
  border-radius: 60px !important;
  overflow: hidden;
  height: 40px !important;
  font-size: 13px !important;
}
.or-signup {
  color: #858597;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 15px 0 10px;
}
.stroke-div {
  width: 103.5px;
  height: 0.5px;
  flex-shrink: 0;
  stroke-width: 0.5px;
  stroke: #b8b8d2;
}
.stroke-wrapper {
  display: flex;
  justify-content: space-evenly;
}
.header-items .loginTxt {
  margin-right: 25px;
  cursor: pointer;
}
.header-items .searchBtn {
  margin-right: 20px;
}
a:hover {
  color: #c2114b;
}
.email-info {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  padding-bottom: 6px;
}
.signup-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100%+30px);
  margin: 0 -15px;
}
.signup-options > .google-media {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  border: 1px solid #ced4da;
  background: #fff;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 0.25rem;
}
.signup-options > .google-media > .media-text {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  text-transform: capitalize;
  padding-left: 8px;
}
.signup-options > .fb-media {
  width: 290px;
  height: 53px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  background: #1976d2;
  border: 1px solid rgb(65 65 65 / 0.4);
  cursor: pointer;
}
.signup-options > .fb-media > .media-text {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  text-transform: capitalize;
  padding-left: 8px;
}
.signup-terms {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 15px 0 0 8px;
  width: 100%;
  margin: 0 auto;
  line-height: 23px;
  text-align: center;
}
.signup-terms-linetwo {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  line-height: 20px;
  text-align: center;
}
.signup-terms-linetwo > span {
  color: #c2114b;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
.kids-signup-terms {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 8px 0;
  width: 100%;
  margin: 0 auto;
  line-height: 23px;
  text-align: center;
}
.kids-signup-terms-linetwo {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  line-height: 20px;
  text-align: center;
}
.kids-signup-terms-linetwo > span {
  color: #c2114b;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
.splitter {
  display: flex;
  max-width: 770px;
}
.splitter-one {
  margin-right: 20px;
  width: 350px;
}
.splitter-two {
  width: 350px;
}
.profession-wrapper,
.choose-pay {
  width: 640px;
}
.choose-pay {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.radio-wrapper > label {
  cursor: pointer;
}
.pay-info {
  padding: 8px 0 8px 0;
}
.pay-label {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: 10px;
}
input[type="radio"] {
  accent-color: #c2114b;
}
.gig-upload {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.gig-img-wrapper {
  width: 100px;
  height: 99.742px;
  border: 1px solid #b1a8a8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.upload-text {
  padding-left: 20px;
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.pay-label {
  padding-bottom: 6px;
  padding-top: 6px;
}
.benefits-dropdown {
  width: 450px;
  padding-bottom: 15px;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 4px 30px;
  border-bottom: 1px solid #adadad;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 65;
  width: 100%;
  background-color: #fff;
}
.filter-section .css-ymxv8s-control,
.filter-section .select_control--menu-is-open {
  min-height: 45px !important;
  height: auto !important;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-height: 64px;
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 9;
  border-bottom: 1px solid #d4d4d8;
}
.header-functions {
  display: flex;
  align-items: center;
  justify-content: end;
}
.hide-nav {
  display: none;
}
.mobile-navbar {
  display: none;
}
.search-bootstrap {
  padding-right: 15px;
}
.search-bootstrap-btn {
  color: #c2114b;
  border-color: #c2114b;
}
.signup {
  border-radius: 60px;
  background: linear-gradient(90deg, #030303 6.98%, #000 65.92%);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 5px 10px;
  cursor: pointer;
  min-width: 150px;
  margin: 0 10px 0 0;
}
.title {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 40px 0 15px 0;
  padding: 0;
}
.moreBtn {
  color: #fff;
  min-width: 150px;
  padding: 5px;
  height: 45px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 60px;
  background: #c2124b;
  border: 1px solid #c2124b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.moreBtn:hover {
  background-color: #fff;
  color: #c2124b;
  border: 1px solid #c2124b;
}
.community-card-wrapper {
  padding: 0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px;
  min-height: 160px;
}
.card-wrapper {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 10px;
  min-height: 240px;
}
.card-background {
  background: #f1f1f1;
}
.boxBg {
  background: #fff;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: rgb(0 0 0 / 11%) 0 2px 8px;
}
.cards-text {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 0 0;
  margin: 0;
}
.count {
  color: #c2114b;
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.input-style {
  height: 50px;
  border-radius: 100px !important;
  border: 1px solid #d4d4d8 !important;
  background: transparent !important;
  color: #fff !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 0 5px 20px !important;
  width: 100%;
  outline: 0 !important;
  box-shadow: inherit !important;
}
.input-style::placeholder {
  color: #fff !important;
}
.subscribe-btn {
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #090914;
  border: 1px solid #090914;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s;
}
.subscribe-btn:hover {
  background: #fff;
  border: 1px solid #090914;
  color: #090914;
}
.main-footer-wrapper .form-fields {
  display: flex;
  padding: 25px 0;
  margin: 0;
  max-width: 60%;
  width: 100%;
}
.main-footer-wrapper .form-fields .form-group {
  padding-left: 7px;
  padding-right: 7px;
}
.company-info {
  color: #000;
  font-size: 13px;
  font-style: normal;
  padding: 10px 0;
  margin: 0;
  line-height: 26px;
  font-weight: 500;
}
.footer-title {
  color: #c2114b !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}
.footer-wrapper h6 {
  margin: 0 0 10px;
  padding: 0;
}
.footerLinks {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footerLinks li {
  margin: 3px 0;
}
.footerLinks li a {
  font-size: 14px;
  font-weight: 400;
}
.copyright-section p {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.copyright-section {
  margin: 35px 0 0 0;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #e2e8f0;
}
.modal-dialog.signupModal {
  max-width: 700px;
}
.modal-backdrop.show {
  opacity: 0.4;
}
.modal-title {
  color: #c2114b;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.signupModal .modal-title {
  padding: 0;
  margin: 0 0 10px 0;
  font-weight: 700;
}
.signupModal .modal-header {
  padding-bottom: 0;
}
.signupModal .modal-body {
  padding: 10px 14%;
}
.modal-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-description {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  padding: 10px 0 40px;
}
.question-model {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  padding: 30px 0 40px;
  text-align: center;
}
.step-title {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 2px 0;
}
.step-text {
  padding-left: 20px;
  height: 47px;
  color: #060606;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.talent-header-main button.base-MenuButton-root {
  background: #fff0;
  border: 0;
  margin: 0 0 0 10px;
  padding: 0;
}
.headerDashboard {
  position: relative;
}
.boxsWhite {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 0.24) 0 3px 8px;
  border-radius: 10px;
}
.sidenavWraper {
  margin: 15px 0 0;
}
.sidenavWraper .icons {
  font-size: 18px;
  font-weight: 700;
  width: 33px;
}
.sidenavWraper .icons.bi-person-gear {
  font-size: 22px;
}
.sidenavWraper .icons.bi-people,
.sidenavWraper .bi.bi-person-add {
  font-size: 20px;
}
.sidenavWraper.scroll {
  overflow-y: auto;
  padding-right: 26px;
}
.scroll::-webkit-scrollbar {
  width: 0.2em;
  background-color: #fff0;
}
.scroll::-webkit-scrollbar-track {
  box-shadow: inherit;
  background-color: #fff0;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 60px;
}
.menu-items {
  display: flex;
  justify-content: space-between;
  justify-content: end;
  align-items: center;
}
.menu-items > div.navTxt {
  padding-right: 35px;
  font-optical-sizing: auto;
}
.menu-items a.nav-link,
.menu-items .navTxt a {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}
.dialog-body.spaceTops {
  padding: 65px 0 25px;
  margin-bottom: 60px;
}
.adult-form-wrapper.ml-0.mr-0 {
  margin-left: 0;
  margin-right: 0;
}
.section-description {
  padding: 10px 0;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  font-family: sans-serif;
  min-height: 120px;
}
.astrix {
  color: red;
  font-size: 16px;
}
.mobile-nav-functions {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.mobile-signup {
  margin: 0 20px;
}
.brand-section {
  justify-content: center;
  width: 100%;
  display: flex;
  min-height: 300px;
  align-items: inherit;
}
.case-study-name {
  padding: 10px 0 5px;
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
.case-study-address {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.social-medias > img {
  cursor: pointer;
  margin-right: 15px;
}
.gallery-top {
  position: relative;
}
.gallery-content {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0, rgb(0 0 0 / 45%) 88%);
  width: 100%;
  display: none;
  padding: 20px 10px 10px;
}
.Join-now-wrapper:hover {
  background: transparent !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: 0 !important;
}
.header-dropdown {
  background-color: #c2114b !important;
  border: unset !important;
  line-height: 0.5 !important;
  cursor: pointer;
  color: #fff !important;
}
.modal-header {
  border-bottom: unset !important;
}
.register-modal {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.register-btn {
  min-width: 200px;
  height: 55px;
  border-radius: 60px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  cursor: pointer;
  width: auto;
}
.choose-register,
.selected-register {
  width: 245px;
  height: 52px;
  border-radius: 6px;
  border: 1px solid #000;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  cursor: pointer;
  margin: 0 8px;
}
.selected-register {
  background: #c2114b;
  color: #fff;
  border: 1px solid #c2114b;
}
.adults-select-btn {
  padding: 0 25px;
  min-width: 180px;
  max-width: 100%;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #fff;
  background: #c2114b;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ageverify-buttons {
  padding-bottom: 40px;
  display: flex;
  justify-content: space-evenly !important;
}
.step-continue {
  width: 140px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 245px;
  border: 1px solid #fff;
  background: #c2114b;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-back,
.step-continue {
  margin: 0 10px;
}
.disabled-continue {
  background: #ccc;
  cursor: unset !important;
}
.gallery-img {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  width: 100%;
  height: 260px;
  object-fit: cover;
  cursor: pointer;
}
.active-tab {
  height: 40px;
  border-radius: 60px;
  background: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}
.brands-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
  overflow: hidden;
}
.brands-section > div > img {
  width: 80px;
}
.Join-now-wrapper {
  min-width: 150px;
  height: 45px;
  border-radius: 60px;
  background: #c2124b;
  border: 1px solid #c2124b;
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.storyCar .carousel-next-control,
.storyCar .carousel-control-prev {
  width: auto;
  height: auto;
  border: 0;
  border-radius: 0;
}
.storyCar .carousel-control-prev {
  left: -35px;
}
.storyCar .carousel-next-control {
  right: -35px;
}
.carousel-next-control,
.carousel-control-prev .carousel-img {
  width: 219px;
  height: 293px;
  border-radius: 16px;
  border: 1px solid #fff;
}
.carousel-img {
  width: 219px;
  height: 293px;
  border-radius: 16px;
  border: 1px solid #fff;
}
.box-one,
.box-two {
  display: flex;
  width: 100%;
}
.carousel-title {
  color: #fff;
}
.secSpac,
.main-footer-wrapper {
  float: left;
  width: 100%;
}
.carousel-section {
  min-height: 524px;
  width: 100%;
  margin-top: 30px;
  float: left;
}
.carousel-section.storyCar {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.carousel-section.storyCar .carousel {
  position: relative;
  margin-bottom: 40px;
}
.carousel-section.storyCar .carousel-wrapper .box-content {
  padding: 0px 5px 5px 15px;
  margin: 0px 0 0;
}
.case-images {
  border-radius: 12px;
  width: 240px;
}
.carousel-icons {
  width: 34px;
  height: 34px;
  background-color: rgb(255 255 255 / 0.1);
}
.carousel-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding-top: 30px;
}
.carousel-description {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  min-height: 170px;
}
.quote > img {
  width: 32px;
  height: 32px;
}
.box-content {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  width: 50%;
}
.box-content .quote {
  margin-bottom: 10px;
}
.storyCar .carimg_Box {
  height: 355px;
  overflow: hidden;
  width: 300px;
}
.storyCar .carimg_Box img {
  min-height: 355px;
  object-fit: cover;
  width: 300px;
}
.get-discover {
  font-size: 32px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-family: sans-serif;
}
.get-discover-description {
  font-family: sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  padding: 10px 0;
  width: 70%;
  line-height: 25px;
  text-align: center;
  font-style: italic;
}
.footer-wrapper {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}
.logoWraper .logospc {
  padding: 0 5px;
}
.profile-section .profImg {
  border: 1px solid #fff;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  overflow: hidden;
}
.profile-section .profImg img {
  min-width: 45px;
  min-height: 45px;
  object-fit: cover;
}
.profile-info {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.profile-name {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.profile-section {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.pad8 {
  padding: 0 8px;
}
.home-cards.row.pad8 {
  padding: 0 5px;
}
.dashHeader .menu-icon {
  padding: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 32px;
  display: none;
}
.dashHeader .menu-icon .fa-bars {
  font-size: 18px;
}
.dashHeader .hide-nav {
  display: flex;
  align-items: center;
}
.show-sidebar.hide-sidebar {
  display: none;
}
.abtWraper .textAlg {
  display: flex;
  align-items: center;
}
.search-path-options {
  padding: 15px;
  margin: 15px 0;
  box-shadow: rgb(60 64 67 / 0.3) 0 1px 2px 0,
    rgb(60 64 67 / 0.15) 0 1px 3px 1px;
  border-radius: 6px;
}
.search-path-wrapper {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 3px;
}
.search-path-wrapper:hover {
  background-color: #ffb4b4;
  cursor: pointer;
}
.search-path-icon {
  font-size: 16px;
  color: #c2114b;
}
.close-icon {
  cursor: pointer;
}
.search-path-labels {
  color: #3d3d3d;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  font-family: sans-serif;
  cursor: pointer;
  padding-left: 10px;
}
.search-path-labels:hover {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  font-family: sans-serif;
}
.talent-profile-icon {
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #eee;
}
.talent-profile-icon-img {
  min-width: 50px;
  min-height: 50px;
  max-width: 100%;
  object-fit: cover;
  cursor: pointer;
}
.header-search-wrapper {
  border: 1px solid #c9c9c9;
  height: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  margin: 0 10px 0 10px !important;
}
.fa-search {
  color: #000;
  cursor: pointer;
}
.fa-search:hover {
  cursor: pointer;
  color: #c2114b;
}
.header-search-icon {
  padding-right: 15px;
}
.header-search-input-style {
  height: 40px;
  border-color: #fff;
  border: 0;
  outline: none !important;
  font-size: 14px;
}
.get-booked-main {
  margin-top: 50px;
  background-color: #eee;
  padding-top: 40px;
}
.recent-gig-favorite {
  padding-left: 15px;
  padding-top: 10px;
}
.base-MenuItem-root {
  outline: 0 !important;
}
.profile-image-header {
  margin-left: 15px;
  padding: 0 !important;
  border: none !important;
  outline: 0 !important;
  box-shadow: inherit !important;
  background: transparent !important;
}
.header-search-wrapper {
  border: 1px solid #c9c9c9;
  height: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
}
.fa-search {
  color: #000;
  cursor: pointer;
}
.fa-search:hover {
  cursor: pointer;
  color: #c2114b;
}
.header-search-icon {
  padding-right: 15px;
}
.header-search-input-style {
  height: 40px;
  border-color: #fff;
  border: 0;
  outline: none !important;
  font-size: 14px;
}
.get-booked-main {
  margin-top: 50px;
  background-color: #eee;
  padding-top: 40px;
}
.recent-gig-favorite {
  padding-left: 15px;
  padding-top: 10px;
}
.favTalent .gallery-wrapper.modalSpc {
  width: 100%;
  margin: 15px 0 5px;
}
.favTalent .contSpc {
  margin-bottom: 15px;
}
.headerDashboard .dropdown-menu,
.header .dropdown-menu {
  right: 0 !important;
  left: inherit !important;
  transform: translate(0, 22px) !important;
  top: -3px !important;
}
.headerDashboard ul.blogs-menu,
.header ul.blogs-menu {
  right: -105px !important;
}
.header .dropdown-menu a.active {
  display: inline-block;
}
.adult-main.remvSpc.col-md-8.col-lg-9 {
  background-color: #fff;
  z-index: 0;
}
.rating-filled-star {
  color: gold;
  font-size: 11px;
  padding-right: 1px;
}
.rating-unfilled-star {
  color: gray;
  font-size: 11px;
  padding-right: 1px;
}
.bannerImg {
  position: relative;
  z-index: 0;
  background-image: url(https://brandsandtalent.com//backend/uploads/f2956536-993b-4710-b05d-8230bd438ac1.webp);
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.bannerImg::after {
  background: linear-gradient(180deg, rgb(0 0 0 / 66%) 37.11%, #000000b3 100%);
  display: block;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.bannerImg .relCont {
  position: relative;
  z-index: 1;
}
.splitterDiv {
  justify-content: space-between;
}
.splitterDiv .form-control {
  width: 48%;
}

a.url-fileName {
  font-size: 13px;
  word-break: break-word;
}

head .profile-image-header {
  margin-left: 15px;
  padding: 0 !important;
  border: none !important;
}
.header-search-wrapper {
  border: 1px solid #c9c9c9;
  height: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.fa-search {
  color: #000;
  cursor: pointer;
}
.fa-search:hover {
  cursor: pointer;
  color: #c2114b;
}
.header-search-icon {
  padding-right: 15px;
}
.header-search-input-style {
  height: 40px;
  border-color: #fff;
  border: 0;
  outline: none !important;
  font-size: 14px;
}
.get-booked-main {
  margin-top: 50px;
  background-color: #eee;
  padding-top: 40px;
}
.recent-gig-favorite {
  padding-left: 15px;
  padding-top: 10px;
}
.fileName {
  padding-left: 8px;
}
.rating-box {
  padding: 15px 50px;
  text-align: center;
}
.rating-box h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.rating-box .stars {
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center;
}
.stars i {
  font-size: 35px;
  color: #b5b8b1;
  transition: all 0.2s;
  cursor: pointer;
}
.stars i.active {
  color: #c2114b;
  transform: scale(1.2);
}
.submit-rating {
  background-color: #c2114b;
  color: #fff;
}
.submit-rating:hover {
  background-color: #c2114b;
  color: #fff;
}
.rating-text-area {
  height: 130px !important;
}
.showContent .gallery-content {
  display: block;
  position: relative;
  background: #fff0;
  color: #000;
  padding: 5px 5px 10px;
}
.showContent .gallery-content .name,
.showContent .gallery-content .address,
.showContent .gallery-content .location-name {
  color: #000;
}
.showContent .gallery-content .location-wrapper > img {
  filter: inherit;
}
.address {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin-bottom: 5px;
}
.location-name {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0 0 0 5px;
}
.name {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 5px 0;
  text-transform: capitalize;
}
.heart-icon {
  position: absolute;
  top: 3%;
  left: 85%;
  cursor: pointer;
}
.gallery-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.gallery-main {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: center;
  margin: 0 -1%;
  width: 100%;
}
.nav-item.dropdown {
  list-style-type: none;
}
.nav-link {
  color: #000;
  font-size: 14px;
  padding: 0;
}
.nav-link:hover {
  color: #c2114b;
}
.dropdown:hover > .dropdown-menu {
  display: block;
  margin-top: 0;
  right: 0;
}
.dropend:hover > .blogs-menu {
  display: block;
  margin-top: -25px;
  margin-left: 160px;
}

.ql-editor {
  height: 150px !important;
}
.cofee-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.cofee-link:hover {
  color: #c2114b;
}
.coffee-icon {
  padding-right: 6px;
  color: #f80;
}
.coffee-icon:hover {
  color: #c2114b;
}
.cofeeIcon-img {
  padding-left: 2px;
  color: #f80;
  width: 25px;
  height: 25px;
}
.handshake-icon {
  font-size: 24px;
  color: #fff;
}
.social-media-icons:hover {
  color: #c2124b;
  cursor: pointer;
}
.product-service-image {
  width: 45px;
  height: 24px;
}
.card-wrapper {
  justify-content: flex-start;
  margin: 40px 0 10px;
}
.add-portfolia-btn.contactus-btn-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.add-portfolia-btn.contactus-btn-wrapper button {
  background: #000;
  border-radius: 30px;
  color: #fff;
}
.terms-container,
.policy-container {
  padding: 30px 15px !important;
}
.accordion-pad {
  max-width: 100%;
}
span.edit-profile-navigation-text {
  font-size: 14px;
  font-weight: 500;
}

.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  /* color: #c2114b; */
}
.resources {
  padding-top: 60px;
}
.topCont.pt-5.pb-0 {
  padding-top: 10px !important;
}
.main-footer-wrapper .form-fields {
  padding-bottom: 0px;
}
.social-medias i {
  padding: 0;
  margin: 0 !important;
  margin-right: 20px !important;
}
.accordion-pad {
  max-width: 100% !important;
}
button.edit-profile-navigation-btn.bk-btn {
  height: 40px;
  padding: 0px 10px;
  background: #ccc;
  border: 0;
}
.section-description {
  min-height: 130px !important;
}
.faqWraper .accordion-button::after {
  /* background-image: url(http://localhost:3001/static/media/minuse-faq.e9d8301….svg) !important; */
  background-image: url(http://localhost:3001/static/media/plus-faq.830864a….svg) !important;
}
.faqWraper .accordion-button.collapsed::after {
  background-image: url(http://localhost:3001/static/media/plus-faq.830864a….svg) !important;
}
.MuiBox-root.css-19kzrtu {
  padding: 0px !important;
}

.terms-and-conditions h1 span,
.terms-and-conditions h2 span,
.terms-and-conditions h3 span {
  font-weight: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
}
.terms-and-conditions h1,
.terms-and-conditions h2,
.terms-and-conditions h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
}
.terms-and-conditions h1 strong,
.terms-and-conditions h2 strong,
.terms-and-conditions h3 strong {
  font-weight: bold;
}
li.nav-item.dropdown {
  list-style-type: none;
}
.abtWraper .descp {
  line-height: 22px;
}
.feedback:hover {
  cursor: pointer;
  color: #c2114b;
}

input::placeholder {
  font-size: 13px !important;
}
.kids-form-section
  .form-group
  .MuiFormControl-root.form-control
  .MuiInputBase-input,
.adult-signup-main
  .form-group
  .MuiFormControl-root.form-control
  .MuiInputBase- {
  padding-left: 0;
  padding-right: 0;
}
.rdw-editor-wrapper {
  border: 1px solid #d3d3d3;
  border-radius: 2px;
}

.adult-signup-main .form-group .MuiFormControl-root.form-control {
  height: 45px !important;
  padding-top: 0;
  padding-bottom: 0;
}
.adult-signup-main
  .form-group
  .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 0;
}

.material-mobile-style {
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  height: 45px;
  padding: 0px 0px 0px 10px;
}

.url-file-wrapper .dropdown > .dropdown-menu {
  min-width: 6rem;
  right: 0 !important;
  left: inherit !important;
}
.media-wrapper .media-info .mediaIcon {
  width: 53px;
}
.media-wrapper .media-info .mediaIcon img {
  max-width: 20px;
  max-height: 20px;
}
.kids-form-row.row.spcBtm {
  margin-bottom: 70px;
}

.update-portfolio-icon .file-section {
  display: flex;
  align-items: center;
}
ul.MuiMenu-list {
  padding: 2px 0;
}

ul.MuiMenu-list li {
  font-size: 13px;
  padding: 5px 10px;
  margin: 0;
}
.service-files-main.uplWraps {
  flex-direction: column;
  align-items: start;
}
.update-portfolio-fileName.pl-0 {
  padding-left: 5px;
}
.url-file-wrapper.urlSect .file-section {
  width: calc(100% - 45px);
}

.url-file-wrapper.urlSect .file-section .url-fileName {
  font-size: 12px;
}

.url-file-wrapper.urlSect .update-portfolio-action {
  width: 45px;
}

.url-file-wrapper.urlSect {
  display: flex;
  align-items: baseline;
  line-height: 17px;
}
.photos-slider .owl-carousel {
  z-index: 0;
}

/* .base-Menu-root ul{  
  position: absolute;
  right: 0;
  left: inherit; z-index: 999
}  */

.dynamic-profession.newAlign .algSepc .form-label {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
}

.invalid-fields {
  font-size: 12px;
  color: red;
}

.topSpc-container {
  margin: 100px 0 50px;
}
.spacHeight-log {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.warning-wrapper.newBoxLog {
  width: 480px;
  margin: 0 auto;
  max-width: 90%;
  padding: 20px 30px;
  text-align: center;
}
.warning-wrapper.newBoxLog .logoImg {
  margin-bottom: 15px;
}
.warning-wrapper.newBoxLog button.warning-btn {
  width: 100%;
  height: 45px;
  margin: 30px 0 10px;
  display: inline-block;
}
.newBoxLog img.logNew-img {
  max-width: 250px;
  margin: 15px auto;
}

.pr-sp {
  padding-right: 0;
}

.payWidth .creator-age-wrapper {
  width: 100%;
  display: block;
}

.carrerList p {
  padding: 0;
  margin: 0;
}

.carrerList .jobtitle {
  color: #c2114b;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 5px 0;
  padding: 0;
}
.carrerList .jobLoct {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin: 5px 0 15px;
  padding: 0;
  /* font-style: italic; */
}
.carrerList .jobLoct span {
  padding-left: 5px;
}

.carrerList .jobDescp {
  font-size: 15px;
  line-height: 24px;
  margin: 10px 0;
  padding: 0;
}

.carrerList {
  border: 1px solid #bab4b473;
  padding: 10px 25px 20px;
  border-radius: 10px;
}
.careerWraps .toptitle {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0;
  padding: 0;
  width: 100%;
}
.carrerList .jobtitle i.bi-suitcase-lg.icons {
  font-size: 20px;
  margin: 0 7px 0 0;
}
.careerWraps .applyInst {
  margin: 15px 0;
  padding: 15px;
  border: 5px solid #c2114b21;
  background-color: #c2114b0d;
  border-radius: 10px;
  text-align: center;
}
.careerWraps .applyInst h6.titles {
  font-size: 22px;
  font-weight: 700;
  margin: 5px 0 15px;
  color: #c2124b;
}
.careerWraps .applyInst p {
  font-size: 16px;
  letter-spacing: 0.2px;
}
.careerWraps .applyInst p a {
  font-weight: 600;
  color: #c2124b;
}

.genCont_desg .descp {
  line-height: 26px;
  font-size: 16px;
  font-weight: 500;
}
.genCont_desg {
  min-height: 50vh;
  display: flex;
  align-items: center;
  padding: 30px 0;
}
.popupcontainer {
  width: 500px !important;
}
.popupcontainer span {
  font-size: 14px !important;
  line-height: 25px;
  /* margin-top: 20px; */
}
.popupcontainer h4 {
  margin-bottom: 20px;
}
.paymentOption span {
  font-size: 13px !important;
}
.paymentOption {
  margin-top: 0% !important;
}
.paymentOptionSection {
  gap: 15px !important;
  height: fit-content !important;
  margin-top: 8% !important;
}
/* 9-1-25 css start  */

.login-main.regPage {
  width: 470px;
}
.talent-header-main {
  z-index: 99999 !important;
}
.messages-section {
  width: 100% !important;
}
.message-user-image {
  width: 50px !important;
  height: 50px !important;
}
.offline-dot,
.online-dot {
  top: 38px !important;
  right: 18px !important;
  width: 12px;
  height: 12px;
}
.message-userdetails-menu-wrapper {
  margin-bottom: 8px !important;
}
.talent-details-wrapper {
  align-items: center !important;
}

.notification-card-image {
  height: 50px !important;
}
.project-file-wrapper {
  width: 100%;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open,
.css-zw3mfo-MuiModal-root-MuiDialog-root,
.modal,
.css-126xj0f {
  z-index: 99999 !important;
}

.css-1vskg8q {
  justify-content: center !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open,
.css-zw3mfo-MuiModal-root-MuiDialog-root,
.modal,
.css-126xj0f {
  z-index: 99999 !important;
}
.css-1vskg8q {
  justify-content: center !important;
}
.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: 9 !important;
  /* z-index: 999999 !important; */
}
.aba-checkout-desktop {
  background: #081b3787;
  z-index: 99999999 !important;
}
.view-gig-btn, .apply-now-btn.applied-btn {
  min-width: 115px !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
  margin-right: 0px !important;
}
.recent-set-three {
gap: 6px;
}
.col-md-8.col-lg-6.scrolscrolPrevOut .recent-set-three.col-lg-5.col-md-12,
 .col-md-8.col-lg-6.scrolscrolPrevOut .mb-2.col-lg-7.col-md-12.logoSpc {
  width: 100% !important;
}
.border-curve {
  border-radius: 30px;
  height: 320px;
  object-fit: cover;
  width: 100%;
}










/* 9-1-25 css end*/

/* media css start */

@media (max-width: 1399px) {
  .menu-items > div.navTxt {
    padding-right: 18px;
  }
  .header-items .loginTxt {
    margin-right: 10px;
  }
}
@media (max-width: 1200px) {
  .btLogo {
    height: 35px !important;
  }
  .navTxt {
    padding-right: 25px;
  }
  .pr-sp {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media (max-width: 991px) {
  .talent-all-details-wrapper .service-list-content .text-btm {
    justify-content: inherit;
    align-items: baseline;
    flex-direction: column;
  }
  .talent-all-details-wrapper .service-list-content .text-btm .enquire-btn {
    margin: 10px 0;
  }
  .brand-section {
    min-height: auto;
  }
  .banner-title-section {
    padding: 90px 0 0 0;
  }
  .brand-options {
    margin: 20px 0;
    width: 100%;
    min-height: auto;
    padding: 0;
  }
  .section-1 {
    height: auto;
    padding-bottom: 30px;
  }
  .section-description {
    min-height: auto;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 25px;
    font-family: sans-serif;
  }
  .section-title {
    margin-bottom: 10px;
    font-size: 26px;
    font-family: sans-serif;
  }
  .brand-section > .brand-description {
    font-family: sans-serif;
    font-size: 16px;
  }
  .gallery-wrapper {
    width: 23%;
  }
  .caseBox {
    height: 256px;
  }
  .caseBox .case-img {
    min-height: 256px;
  }
  .caseWraper .case-wrapper {
    margin-bottom: 25px;
  }
  .caseWraper .find-more {
    padding-top: 20px;
  }
  .box-one,
  .box-two {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px;
  }
  .main-footer-wrapper .form-fields {
    padding: 25px;
    max-width: 100%;
    width: 100%;
  }
  .dashHeader .menu-icon {
    display: inline-block;
  }
  .dashHeader .hide-nav {
    display: none;
  }
  .dashHeader .menu-items {
    display: flex;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: inherit;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .dashHeader .mobile-nav-content {
    padding: 20px;
    left: 0;
  }
  .dashHeader .mobile-nav-content .navTxt {
    padding: 10px 0 !important;
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0;
  }
  .show-sidebar.hide-sidebar {
    display: block !important;
  }
  .show-sidebar {
    display: none;
  }
  .brand-main-container {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .invalid-fields {
    width: 100% !important;
    line-height: 17px;
    font-size: 12px;
}
  .view-gig-btn, .apply-now-btn.applied-btn {
    min-width: 85px !important;
}
.userBox .campaigns-content-wrapper .recent-set-three {
  justify-content: flex-start !important;
}
li.job-features-li {
  width: 100% !important;
}
  /* 9-1-25 css start  */
  .css-89200c-MuiFormControl-root-MuiTextField-root svg {
    height: 1em;
    position: absolute;
    top: 0px;
  }

  /* 9-1-25 css end  */
  .talentSet .edit-basicdetails-section-main {
    justify-content: center;
    width: 100%;
  }
  .brand-content-main.talentSet.boxBg .cardHeight {
    min-height: auto !important;
  }
  .step-back,
  .step-continue {
    margin: 0 5px;
  }

  .kids-form-row.row.spcBtm {
    margin-bottom: 20px;
  }

  .explore-info {
    font-size: 14px;
    line-height: 21px;
  }
  .step-back,
  .step-continue {
    margin: 0 5px;
  }

  .kidsform-one .kids-wrapper {
    margin: 0;
  }
  .kidsform-one .kids-wrapper {
    margin: 0;
  }
  #bmc-wbtn {
    width: 50px !important;
    height: 50px !important;
  }
  .gallery-wrapper {
    width: 31%;
    float: left;
  }
  .gallery-main {
    float: left;
    width: 100%;
  }
  .wraper {
    float: left;
    width: 100%;
  }
  .moreBtn {
    margin: 15px 0 0;
  }
  .container {
    padding: 0 20px;
  }
  .storyCar .box-one,
  .storyCar .box-two {
    padding: 0 12px;
  }
  .storyCar .carousel-description {
    font-size: 20px;
    line-height: 33px;
    min-height: 145px;
  }
  .card-wrapper {
    min-height: auto;
    margin: 10px 0 25px;
  }
  .get-discover {
    font-size: 30px;
    margin: 20px 0 0 !important;
    padding: 0;
  }
  .main-footer-wrapper .form-fields .form-group {
    margin-bottom: 15px;
  }
  .footer-wrapper h6 {
    margin: 0 0 5px;
  }
  .footer-wrapper {
    margin: 20px 0 0;
  }
  .sidenavWraper.scroll {
    padding-right: 15px;
  }
  .talent-profile {
    margin-right: 15px !important;
  }
  .headerDashboard .nav-item {
    font-size: 14px;
    font-weight: 400;
    list-style: none !important;
  }
  .headerDashboard .nav-item a {
    color: #000;
    padding: 0;
  }
  .mobile-nav-content .header-search-wrapper {
    margin: 10px 0 !important;
  }
  .header-search-wrapper .header-search-icon {
    padding: 0 !important;
  }
  .mobile-nav-functions .login-text {
    margin-right: 15px !important;
  }
  .headerDashboard .dropdown-menu {
    padding: 0.1rem 0;
  }
  .headerDashboard .dropdown-menu li {
    padding: 5px 10px;
  }
  .notification-card-flex img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 40px;
    min-height: 50px !important;
    min-width: 50px !important;
  }
  .talent-notification-card.notification-read {
    padding: 15px 0px !important;
    position: relative;
  }
  .notification-card-time {
    position: absolute;
    font-size: 11px;
    bottom: 5px;
  }
  .notification-card-flex {
    align-items: flex-start !important;
  }
  .dialog-body.mt-0.mobile-mb-0 {
    margin-bottom: 0px !important;
  }
  .spd div {
    text-align: left !important;
  }
  .spd {
    display: flex !important;
    flex-direction: column !important;
  }
  .hd-sett {
    font-size: 20px !important;
  }
  .nav.menu-items ul.dropdown-menu.show {
    position: relative !important;
  }
  .border-curve {
    margin-bottom: 20px;
}
}
@media (max-width: 580px) {
  .section-description {
    font-size: 14px;
    font-family: sans-serif;
    line-height: 22px;
  }
  .banner-title-section {
    padding-bottom: 5px;
  }
  .brand-options .section-title {
    font-size: 24px;
    font-family: sans-serif;
    color: #fff;
  }
  .joinnow-text {
    font-size: 14px;
  }
  .Join-now-wrapper {
    margin-top: 5px;
  }
  .caseBox {
    height: 286px;
  }
  .caseBox .case-img {
    min-height: 286px;
  }
  .caseWraper .find-more {
    padding: 0;
  }
  .storyCar .box-one,
  .storyCar .box-two {
    width: 50%;
  }
  .storyCar .carousel-description {
    font-size: 16px;
    line-height: 28px;
    /* height: 100px; */
  }
}
@media (max-width: 480px) {
  section.footerCommon.pt-5 {
    padding-top: 1rem !important;
  }
  .get-discover {
    font-size: 30px;
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .storyCar .box-one,
  .storyCar .box-two {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .gallery-wrapper:hover .gallery-content {
    display: block;
  }
  .kids-select-btn,
  .adults-select-btn {
    width: 100%;
    max-width: 160px;
    height: 40px;
    border-radius: 25px;
    border: 1px solid #fff;
    background: #c2114b;
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ageverify-buttons {
    padding-bottom: 40px;
    display: flex;
    justify-content: space-evenly !important;
  }
  .ageverify-title {
    color: #c2114b;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 20px 0 30px 0;
  }
  .login-text {
    font-weight: 600;
  }
  .title {
    font-size: 20px;
    padding: 0;
    margin: 30px 0 15px;
  }
  .get-discover.title {
    font-size: 20px;
  }
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .tabs > div {
    margin: 10px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
  }
  .active-tab {
    height: 30px;
    border-radius: 140px;
    background: #000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    max-width: 140px;
    padding: 15px;
  }
  .heart-icon {
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
  }
  .name {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 8px 0;
  }
  .address {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 3px 0;
  }
  .cards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }
  .community-card-wrapper {
    padding: 15px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0 20px;
    width: 100%;
    min-height: 125px;
    height: auto;
  }
  .card-wrapper {
    padding: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    min-height: 100px;
    height: auto;
  }
  .card-title {
    font-size: 16px;
    padding: 10px 0 5px;
  }
  .case-study-address {
    padding: 5px 0;
  }
  .case-study-name {
    padding: 10px 0 10px 0;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .card-background {
    border-radius: 24px;
    background: #f1f1f1;
  }
  .count {
    color: #c2114b;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .cards-text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-top: 8px;
  }
  .cards-description {
    padding-bottom: 10px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .available-places {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-style: italic !important;
  }
  .case-images {
    border-radius: 12px;
    width: 150px;
  }
  .carousel-section {
    margin-top: 50px;
  }
  .carousel-title {
    color: #fff;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin: 30px 0 10px;
  }
  .box-one {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 500px;
  }
  .carimg_Box {
    width: 275px;
    height: 315px;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid #fff;
  }
  .carousel-img {
    width: 100%;
    min-height: 315px;
    object-fit: cover;
  }
  .box-content {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
  }
  .quote > img {
    width: 20px;
    height: 20px;
  }
  .carousel-description {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-align: left;
  }
  .box-two {
    display: none;
  }
  .profile-section {
    display: flex;
    padding: 10px 0 !important;
  }
  .profile-name {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .profile-info {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
  }
  .carousel-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .storyCar .carousel-description {
    min-height: 80px;
  }
  .carousel-icons {
    width: 34px;
    height: 34px;
    background-color: rgb(255 255 255 / 0.1);
  }
  .storyCar .box-one,
  .storyCar .box-two {
    padding: 0;
  }
  .storyCar .box-one {
    margin: 0 0 25px;
  }
  .brands-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  .brands-section > div {
    padding: 18px 10px;
  }
  .brands-section .logospc img {
    max-width: 80px;
  }
  .form-title {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
  }
  .main-form {
    display: flex;
    justify-content: center;
  }
  .question-model {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 30px 0;
    text-align: center;
  }
  .signupModal .question-model {
    padding-bottom: 0;
  }

  .register-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
  }
  .register-btn {
    width: 170px;
    height: 45px;
    border-radius: 161px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 37px;
    cursor: pointer;
  }
  .user-details {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
  }
  .location-wrapper {
    padding: 4px 0;
  }
  .choose-register,
  .selected-register {
    min-width: 160px;
    max-width: 100%;
    border-radius: 6px;
    border: 1px solid #000;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    cursor: pointer;
    padding: 4px;
    margin: 0 5px;
  }
  .selected-register {
    border: 1px solid #c2114b;
    background: #c2114b;
    color: #fff;
    cursor: pointer;
  }
  .modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-description {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    padding: 30px 0;
  }
  .modal-title {
    color: #c2114b;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 10px 0;
    margin-top: 0;
  }
  .modal-wrapper {
    max-width: 475px !important;
    max-height: 569px !important;
  }
  .modal-logo {
    padding-right: 20px;
    height: 30px;
  }
  .step-text {
    padding-left: 20px;
    border-left: 1px solid #d9d9d9;
    height: 47px;
    color: #060606;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    text-transform: capitalize;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .step-title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding: 20px 0;
  }
  label {
    font-size: 13px;
  }
  .step-description {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 10px 0 30px 0;
    text-align: center;
  }
  .step2-selection {
    display: flex;
    justify-content: center;
  }
  .step-section-1 {
    margin-right: 25px;
  }
  .step-input {
    background: #fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
  }
  .step-select {
    margin: 20px 0;
  }
  .select-wrapper {
    display: flex;
    align-items: center;
    width: 450px;
    height: 55px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
    padding: 20px;
    margin: 10px 0;
  }
  .select-text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-left: 20px;
    cursor: auto;
    width: 400px;
  }
  .form-label {
    margin-bottom: 0.1rem;
  }
  .step-back {
    width: auto;
    height: 40px;
    min-width: 100px;
    flex-shrink: 0;
    border-radius: 60px;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }
  .step-continue {
    width: 150px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 245px;
    border: 1px solid #fff;
    background: #c2114b;
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    cursor: pointer;
  }
  .upload-step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .upload-wrapper {
    width: 320px;
    height: 50px;
    background: #0e0e0e;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  /* .upload-text {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 20px;
  } */
  .import-wrapper {
    width: 320px;
    height: 55px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
  }
  .import-text {
    color: #0e0e0e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 20px;
  }
  .brand-section > p > span {
    color: #c2114b;
  }
  .brand-sidebar-container {
    padding: 0 0 15px 15px !important;
  }
  .mobile-nav-functions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .mobile-signup {
    min-width: 140px;
    height: 35px;
    margin: 0 10px;
  }
}

@media (max-width: 430px) {
  .gallery-wrapper {
    width: 47%;
    margin: 15px 1.5% 0;
  }
  .dashboard-main .find-more {
    float: left;
    width: 100%;
  }
  .dashboard-main .find-more .moreBtn {
    float: left;
    width: 100%;
    margin: 20px 0;
  }
  .community-card-wrapper {
    margin: 5px 0 15px;
  }
  .brand-section > .brand-title {
    font-size: 34px;
    margin: 0 0 25px !important;
    line-height: 42px;
    font-family: "emoji";
  }
}

@media (min-width: 480px) {
  .nav-item.dropdown {
    list-style-type: inherit;
  }

  .nav-link {
    color: #000;
    font-size: 14px;
    padding: 0;
  }
  .nav-link:hover {
    color: #c2114b;
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
    margin-top: 0;
    right: 0;
  }
  .dropend:hover > .blogs-menu {
    display: block;
    margin-top: -25px;
    margin-left: 160px;
  }
  .step2-selection {
    display: flex;
    justify-content: center;
  }
  .step-section-1 {
    margin-right: 25px;
  }
  .step-input {
    background: #fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
  }
  .step-select {
    margin: 20px 0;
    border: 0;
  }
  .select-text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-left: 20px;
    cursor: auto;
    width: 100%;
  }
  .password-input {
    width: unset !important;
  }
  .select-text-only {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-left: 0;
    cursor: auto;
    width: 100%;
  }
  .step-back {
    width: 130px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 245px;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .kids-select-btn {
    padding: 0 25px;
    min-width: 180px;
    max-width: 100%;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #fff;
    background: #c2114b;
    color: #fff;
    padding: 0 25px;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload-step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .upload-wrapper {
    width: 350px;
    height: 50px;
    background: #0e0e0e;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  /* .upload-text {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 20px;
  } */
  .import-wrapper {
    width: 350px;
    height: 55px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
  }
  .import-text {
    color: #0e0e0e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 20px;
  }
  .step-description {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 10px 0 30px 0;
    text-align: center;
  }
  .ageverify-title {
    color: #c2114b;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 10px 0 50px 0;
  }
  .modal-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
  .cards-description {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .card-picture {
    border-radius: 104px;
    border: 1px solid #fff;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 60px;
    height: 60px;
  }
  .cards {
    display: flex;
    justify-content: space-evenly;
  }
  .case-study-main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    justify-content: space-evenly;
  }
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabs > div {
    margin: 20px 5px;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
    padding: 5px 20px;
  }
  .white-joinnow > div {
    color: #fff;
  }
  .white-joinnow {
    border-radius: 140px;
    border: 1px solid #fff;
    background: #fff0;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    cursor: pointer;
  }
  .find-more {
    display: flex;
    justify-content: center;
    padding: 40px 0 30px 0;
  }
  .hire-wrapper {
    width: 150px;
    height: 45px;
    border-radius: 20px;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    margin-top: 15px;
    text-align: center;
  }
  .menu-items > div.navTxt.padRmv {
    padding-right: 10px;
  }
  .section-title {
    color: #fff;
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: sans-serif;
  }
  .mobile-section {
    display: none;
  }
  .dropdown-item {
    color: #000;
    font-size: 12px;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "wdth" 100;
  }
  .dropdown-item:hover {
    background-color: #ff8fb4;
  }
  .gridLogo {
    width: 40px;
    height: 40px;
    background-color: #c2114b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
@media (max-width: 1200px) {
  .mobile-nav-content > div {
    margin: 12px 0;
  }
  .mobile-nav-content {
    visibility: visible;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    position: fixed;
    background: #fff;
    width: 100%;
    bottom: 0;
    top: 68px;
    height: 100vh;
    z-index: 9;
  }
  .mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed !important;
    top: 0;
    align-self: flex-start;
    padding: 20px;
    border-bottom: 1px solid #d4d4d8;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    height: 69px;
  }
  .header {
    display: none;
  }
  .mobile-menu-items {
    margin-bottom: 0 !important;
  }
  .mobile-menu-items > div {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    padding-right: 30px;
    text-decoration: none;
    margin: 25px 0;
  }
  .signup,
  .login-text {
    font-size: 14px;
    font-weight: 500;
  }
  a.active {
    max-width: unset;
    height: unset;
    border-radius: unset;
    background: unset;
    cursor: pointer;
    display: unset;
    justify-content: unset;
    align-items: unset;
    color: #000 !important;
    padding: unset;
  }
  .fa-bars {
    cursor: pointer;
    font-size: 26px;
  }
  .search-bootstrap {
    padding-right: 15px;
    width: 30%;
  }
  .search-bootstrap-btn {
    color: #c2114b;
    border-color: #c2114b;
  }
}
@media only screen and (min-width: 1200px) {
  .mobile-nav-content {
    display: none;
  }
  .brands-row-title {
    margin: 15px 0;
  }
}
@media only screen and (min-width: 1500px) {
  .mobile-nav-content {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .kids-main .media-wrapper {
    padding: 5px 5px 5px 10px;
  }
  .kids-form-row.row.mb-4 {
    margin-bottom: 0rem !important;
  }
  .media-wrapper .media-info .mediaIcon {
    width: 45px;
  }

  .signup-terms {
    font-size: 12px;
  }
  .dialog-body.spaceTops {
    padding: 0px 0 25px;
    margin-bottom: 60px;
  }
  .carousel-section.storyCar .carousel-wrapper .box-content {
    padding-left: 0;
  }
  .signup-terms-linetwo {
    font-size: 12px;
  }
  .signup-terms-linetwo > span {
    font-size: 12px;
  }
  .case-warpper {
    width: 80%;
  }
  .mobile-blogs-menu {
    top: 56px;
  }
  .dialog-footer {
    justify-content: center !important;
    padding: 15px 15px !important;
  }
  .search-bootstrap {
    width: 100% !important;
    padding: 0;
  }
  .form-title {
    padding-top: 30px;
  }
  .form-fields {
    display: flex;
    padding: 25px;
    width: 100%;
    align-items: center;
  }
  .form-fields > input {
    margin: 15px 15px;
  }
  .form-fields {
    display: flex;
    padding-top: 25px;
    flex-direction: column;
  }
  .brand-section > .brand-title {
    font-size: 30px !important;
    text-align: center !important;
  }
  .brand-section > .brand-description {
    color: #fff;
    font-size: 16px;
    text-align: center !important;
  }
  .section-description {
    padding-top: 15px;
    width: 90%;
    color: #fff;
    text-align: center;
    font-family: sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .brand-options {
    width: 100%;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .join-talent-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .hire-talent-section {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }
  .section-title {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: sans-serif;
    text-align: center;
  }
  .case-study-main {
    display: flex;
    flex-direction: column;
  }
  .location-name {
    font-size: 12px;
  }
  .card-picture {
    border-radius: 104px;
    border: 1px solid #fff;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 60px;
    height: 60px;
  }
  .brands-row-title {
    margin: 15px 0;
  }
}
@media only screen and (min-width: 480px) {
  .mobile-carousel {
    display: none;
  }
  .web-carousel {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .mobile-carousel {
    display: block;
  }
  .web-carousel {
    display: none;
  }
  .kids-main .Or-seperator {
    padding: 10px 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .case-warpper {
    width: 40%;
  }
  .search-bootstrap {
    width: 100% !important;
  }
  .form-fields > input {
    margin: 15px 0;
  }
  .form-fields {
    display: flex;
    padding: 25px;
    width: 100%;
    align-items: center;
  }
  .form-fields > input {
    margin: 15px 15px;
  }
  .form-fields {
    display: flex;
    padding-top: 25px;
  }
  .brand-section > .brand-title {
    font-size: 40px !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .case-warpper {
    width: 30%;
  }
  .brand-section > .brand-title {
    font-size: 40px !important;
  }
  .search-bootstrap {
    width: 50% !important;
  }
  .form-fields {
    display: flex;
    padding: 25px;
    width: 100%;
    align-items: center;
  }
  .form-fields > input {
    margin: 15px 15px;
  }
  .form-fields {
    display: flex;
    padding-top: 25px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .form-fields {
    display: flex;
    padding: 25px;
    width: 100%;
  }
  .search-bootstrap {
    width: 50% !important;
  }
}
@media (max-width: 580px) {
  .option-menu .dropdown-item {
    font-size: 14px;
  }
  .file-options .dropdown:hover > .dropdown-menu {
    right: 0 !important;
    transform: inherit !important;
    margin: 0 !important;
    left: inherit !important;
    bottom: 28px !important;
    padding: 2px 0;
    top: inherit !important;
    min-width: 7rem;
  }
  .header-wrapper {
    padding: 4px 20px;
  }
  .header-wrapper .step-text {
    height: auto;
    font-size: 12px;
    font-weight: 600;
  }
  .header-wrapper .btn-close {
    width: 0.5em;
    height: 0.5em;
    padding: 3px;
    margin: 0 -6px 0 0;
  }
  .adults-form-title {
    font-size: 20px;
  }
  .adults-titles,
  .kids-form-title {
    font-size: 15px !important;
    padding: 10px 0;
  }
}
.kidsform-one .kids-wrapper .kids-form .dynamic-profession {
  gap: 12px;
}
.profession-checkbox {
  width: 15px;
  height: 15px;
}
.offer-wrapper {
  padding-top: 0px;
  margin-bottom: 1rem !important;
}

.company-location.comp-main {
  align-items: flex-start;
  display: block !important;
}
span.comp-H {
  flex: 1;
}

span.comp-H a {
  word-break: break-word;
}

input.form-control.adult-signup-inputs.pl-0x {
  padding-left: 0.75rem !important;
}
main.brand-main-container:not(.main-pd) .keyword-wrapper,
main.brand-main-container:not(.main-pd) .profession-creator-wrapper {
  flex-direction: column;
}
main.brand-main-container:not(.main-pd) .filter-items,
main.brand-main-container:not(.main-pd) .keyword-wrapper .inpWid,
main.brand-main-container:not(.main-pd)
  .profession-wrapper.talents-profession.inpWid,
main.brand-main-container:not(.main-pd) .filter-items,
main.brand-main-container:not(.main-pd) .creators-filter-select {
  width: 100% !important;
}

@media (max-width: 767px) {
  .css-1yed7cp {
    position: absolute;
    top: 10%;
    left: 49%;
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    width: 397px;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    padding: 32px;
  }

  .header-search-wrapper {
    padding: 5px !important;
    width: 45px;
  }
  .dialog-body.spaceTops,
  .dialog-body {
    padding: 0;
    margin: 70px 0 85px !important;
  }
  .terms-and-conditions h1 span,
  .terms-and-conditions h2 span,
  .terms-and-conditions h3 span {
    font-weight: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
  }
  .terms-and-conditions h1,
  .terms-and-conditions h2,
  .terms-and-conditions h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
  }
  .terms-and-conditions h1 strong,
  .terms-and-conditions h2 strong,
  .terms-and-conditions h3 strong {
    font-weight: bold;
  }
  li.nav-item.dropdown {
    list-style-type: none;
  }
  .abtWraper .descp {
    line-height: 22px;
  }
  .feedback:hover {
    cursor: pointer;
    color: #c2114b;
  }
  .kidsform-one .kids-wrapper .kids-form .dynamic-profession {
    gap: 12px !important;
  }

  .signupModal .modal-body {
    padding: 10px 15px;
  }
  .mobile-search {
    margin-left: 4px !important;
  }
  .section-description {
    min-height: inherit !important;
  }
  /* .dropdown:hover > .dropdown-menu {
    position: relative !important;
    transform: unset !important;
  } */
  .storyCar .carousel-next-control {
    right: 0;
    top: 200px;
    height: 0;
  }
  .carousel-section.storyCar .carousel-wrapper .box-content {
    padding: 0;
    margin: 0;
  }
  .storyCar .carousel-control-prev {
    left: 0;
    top: 200px;
    height: 0;
  }
  .btLogo {
    height: 40px !important;
  }
  .fa-bars {
    cursor: pointer;
    font-size: 20px;
  }
  .brand-section > .brand-title {
    font-size: 39px !important;
    margin: 0 0 15px !important;
    font-family: sans-serif;
    text-align: left;
    line-height: 50px;
  }
  .brand-section > .brand-description {
    text-align: left;
  }
  .banner-title-section {
    padding: 120px 0 0 0;
  }
  section.section-1.bannerImg.wraper {
    background-position: inherit !important;
    padding-left: 8px;
    padding-right: 8px;
  }
  .bannerImg::after {
    background: linear-gradient(180deg, rgb(0 0 0 / 66%) 47.11%, #c2124b 100%);
  }
  .Join-now-wrapper.hireBtn {
    background: #c2124b;
    color: #fff;
  }
  .Join-now-wrapper.hireBtn .joinnow-text {
    color: #c2124b;
  }
  .brand-options {
    background: #00000040;
    padding: 20px;
    height: auto;
    margin-bottom: -5px;
    border-radius: 15px;
  }
  .Join-now-wrapper {
    width: 100%;
  }
  .brand-section.flex-column.banner-title-section {
    margin-bottom: 15px;
  }
  .card-wrapper {
    border: 1px solid #ccc;
    padding: 30px;
    border-radius: 10px;
  }
  .productsWraper.wraper.secSpac .card-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .storyCar .carimg_Box img {
    height: 165px;
    object-fit: cover;
    width: 100%;
  }
  .storyCar .carimg_Box {
    height: 285px;
    overflow: hidden;
    width: 70%;
  }
  .storyCar .carousel-description {
    min-height: 80px;
    text-align: center;
  }
  .box-content .quote {
    margin: 5px 0 10px;
    display: flex;
    justify-content: center;
  }
  .brands-section {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
  }
  .footer-section.row div:nth-child(1) {
    order: 5;
  }
  .footer-section.row div:nth-child(2) {
    order: 1;
  }
  .footer-section.row div:nth-child(3) {
    order: 2;
  }
  .footer-section.row div:nth-child(4) {
    order: 3;
  }
  .footer-section.row div:nth-child(5) {
    order: 4;
  }
  .footer-wrapper.col-md-4.col-lg-4 {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 50px;
    margin-bottom: -20px;
  }
  .footer-icon {
    margin-bottom: 20px;
  }
  .social-medias > div {
    display: flex;
    justify-content: space-between;
  }
  .box-one.box-two {
    display: none;
  }
  .profile-section {
    justify-content: center;
  }

  .quote > img {
    width: 30px;
    height: 30px;
    opacity: 0.5;
  }
  .brands-section .logospc img {
    max-width: 70px;
    padding: 10px;
  }
  .title {
    font-size: 26px;
  }
  .main-footer-wrapper .form-fields {
    padding: 15px 0;
  }
  section.main-footer-form {
    padding: 10px 15px 5px;
  }
  .main-footer-wrapper .form-fields .form-group {
    margin-bottom: 5px;
    padding: 0;
  }
  .get-discover-description {
    width: 100%;
    padding: 0 5px;
    margin-top: 10px !important;
  }
  .mobile-signup {
    height: 38px;
    font-size: 12px;
    min-width: auto;
    padding: 0 15px;
  }
  .login-container {
    border: 1px solid #aaa !important;
  }
  .card-wrapper {
    margin: 10px 0 10px;
  }
  .howitworks-labels {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  main.brand-home-main {
    width: 100% !important;
  }
  .update-password-main.w-100 {
    padding: 0;
    margin-top: 20px;
  }
  .add-portfolia-btn.contactus-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .add-portfolia-btn.contactus-btn-wrapper button {
    background: #000;
    color: #fff;
    opacity: 1;
    border-radius: 30px;
    margin-left: 20px;
  }
  textarea#exampleFormControlTextarea1 {
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .terms-container.container,
  .policy-container {
    padding: 20px !important;
  }
  .terms-and-conditions h3 span,
  .terms-and-conditions h1 span,
  .terms-and-conditions h2 span {
    font-weight: 600;
  }
  .terms-and-conditions h3,
  .terms-and-conditions h1,
  .terms-and-conditions h2 {
    margin-top: 10px !important;
  }
  div#carouselExampleControls button {
    opacity: 1;
  }

  main.brand-home-main {
    width: 100% !important;
  }
  .update-password-main.w-100 {
    padding: 0;
    margin-top: 20px;
  }
  .add-portfolia-btn.contactus-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .add-portfolia-btn.contactus-btn-wrapper button {
    background: #000;
    color: #fff;
    opacity: 1;
    border-radius: 30px;
    margin-left: 20px;
  }
  textarea#exampleFormControlTextarea1 {
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .terms-container.container,
  .policy-container {
    padding: 20px !important;
  }
  .terms-and-conditions h3 span,
  .terms-and-conditions h1 span,
  .terms-and-conditions h2 span {
    font-weight: 600;
  }
  .terms-and-conditions h3,
  .terms-and-conditions h1,
  .terms-and-conditions h2 {
    margin-top: 10px !important;
  }
  div#carouselExampleControls button {
    opacity: 1;
  }
  .social-medias i {
    margin: 0 !important;
  }
  /* .MuiFormControl-root.MuiTextField-root.form-control.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
    input {
    height: 30px !important;
  } */
  .mobile-menu-items .navTxt:nth-child(3) {
    padding-right: 0px !important;
  }

  .dropdown .dropdown-menu.show {
    display: block !important;
  }

  /* ul.dropdown-menu {
    display: none !important;
  }
  .url-file-wrapper ul.dropdown-menu {
    display: block !important;
  } */

  .url-file-wrapper .dropdown > .dropdown-menu {
    position: absolute !important;
    transform: unset !important;
    right: 0px !important;
    top: 35px !important;
    left: inherit !important;
    min-width: 6rem;
    padding: 3px 0;
    font-size: 14px;
  }
  button#dropdownMenuButton1 {
    background: none;
    font-weight: 500;
  }
  a.dropdown-item.mb-f {
    font-size: 14px;
  }
  ul.dropdown-menu.show {
    margin-top: 10px !important;
  }
  /* .dropdown > .dropdown-menu {
    position: relative !important;
    transform: unset !important;
  } */
  button.edit-profile-navigation-btn.bk-btn i,
  button.edit-profile-navigation-btn.bk-btn span {
    color: #fff;
  }
  .add-portfolia-btn.contactus-btn-wrapper {
    gap: 0px;
  }
  .header-search-input,
  .header-search-input input {
    width: 100%;
  }
  .mobile-menu-items {
    height: 500px;
    overflow-y: auto;
  }
  .mobile-nav-content {
    justify-content: flex-start;
  }
  .kidsform-one .kids-wrapper .kids-form .kids-title,
  .kidsform-one .kids-wrapper .kids-form .kids-form-title {
    color: #000;
    font-size: 22px;
  }
  .kidsform-one .kids-wrapper {
    margin: 0px 0;
  }
  .dialog-body.spaceTops {
    padding: 0px 0 25px;
  }
  .kids-form-title > span {
    display: inline-block;
    background-color: #fff;
    padding: 5px 15px 5px 0;
  }
  .file-section a {
    word-break: break-all;
  }
  .public-url-text {
    word-break: break-all;
  }
  .individual-talent-tabs {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    display: flex;
    /* flex-wrap: nowrap; */
  }
  .individual-talent-tab {
    width: fit-content !important;
  }
  .individual-talent-tab-first {
    max-width: fit-content !important;
  }
  .photos-gallery-image img {
    width: 100% !important;
  }
  .talent-rates .pr-0,
  .talent-rates .col-md-8.col-lg-9 {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 20px;
  }

  .cv-card {
    width: 100% !important;
    flex-direction: column;
  }
  button.view-cv {
    width: 100% !important;
    height: inherit;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    margin-bottom: 0px;
  }
  .individual-talent-tabs .active-tab,
  .individual-talent-tab-first-active-tab.individual-talent-tab-first {
    border-radius: 0px !important;
    border-bottom: 2px solid #000;
    background: none;
    color: #000 !important;
    font-weight: bold;
  }
  .photos-gallery .photos-gallery-image {
    height: inherit;
    margin-bottom: 20px;
    margin-right: 0px !important;
  }
  section.photos-gallery.w-100 > div {
    width: 100%;
  }
  .view-all {
    min-width: inherit !important;
    height: 28px !important;
    flex-shrink: 0;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 60px;
    border: 1px solid #000;
    background: rgba(3, 3, 3, 0);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    transition: 0.5s;
    font-size: 12px;
  }
  .table-container table tbody > tr {
    display: flex;
    flex-direction: column;
  }
  .carrerList .jobtitle {
    font-size: 20px;
    margin: 10px 0;
  }
  .carrerList .jobtitle i.bi-suitcase-lg.icons {
    font-size: 18px;
  }
  .carrerList {
    padding: 15px;
  }
  .careerWraps .applyInst p {
    font-size: 15px;
  }
  .base-Popup-root.base-Menu-root {
    transform: inherit !important;
  }
  .rating {
    gap: 4px;
    width: inherit;
  }
  .creatorPage-Wraper .col-md-8.col-lg-8 {
    padding: 0;
  }
  .creatorPage-Wraper .models-images {
    padding: 10px;
  }

  .favTalent .col-md-4.col-lg-3.px-1 {
    width: 50%;
  }
  .find-more.mt-2.pt-0 {
    margin-bottom: 20px;
    padding: 20px;
  }

  .messages-section {
    width: 100%;
  }
  img.job-company-logo {
    margin-right: 10px;
    border-radius: 60px;
  }
  span.job-company-name {
    font-weight: 700;
  }
  span.job-feature-values span {
    word-break: break-word;
  }
  .not-viewjob {
    margin: 100px 16px 20px !important;
  }
  .company-location {
    padding: 6px 0 !important;
  }
  .job-feature-heading {
    font-weight: 700 !important;
  }
  .scrolscrolPrevOut.not-viewjob .preview-main-box {
    margin-top: 0px !important;
  }
  .brand-headings-wrapper.mobilevw {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .brand-headings-wrapper.mobilevw .create-job-title.w-50,
  .brand-headings-wrapper.mobilevw .sample-profile {
    width: 100% !important;
  }











  @media (max-width: 768px) {
    .base-Popup-root {
      left: auto !important;
      right: 10px !important; /* Adjust to your preference */
      top: 50px !important; /* Adjust to your preference */
      transform: none !important;
    }
  }
}
@media (max-width: 420px) {
  .mobile-navbar .icon {
    width: 45px;
  }
  .genCont_desg {
    padding: 15px 0;
  }
  .carrerList .jobtitle i.bi-suitcase-lg.icons {
    display: none;
  }
  .mobile-menu-items {
    height: fit-content;
    overflow-y: hidden;
  }
  .kids-signup-terms.mb-5 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 380px) {
  .ageverify-buttons {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .adults-select-btn,
  .kids-select-btn {
    width: 100%;
    margin: 5px 0;
    max-width: 100%;
    height: 45px;
  }
  .ageverify-title {
    padding-top: 0;
  }

  .material-mobile-style {
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    height: 45px;
    padding: 6px 0px 0px 10px;
  }
}

/* .navTxt {
  margin: 14px 0 !important;
} */

.navTxt.active {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #c2114b !important; /* Use the same color as the NavLink active state */
  font-size: 15px;
}

.material-mobile-style .MuiInputAdornment-root img {
  display: block !important; /* Ensures the flag is always shown */
  width: auto !important; /* Adjust width if necessary */
  height: auto !important; /* Adjust height if necessary */
}

@supports (-webkit-touch-callout: none) {
  .material-mobile-style .MuiPhoneNumber-flag {
    display: inline-block !important;
  }
}

.material-mobile-style .MuiPhoneNumber-flag {
  display: inline-block !important;
  width: 24px !important; /* Adjust as per need */
  height: 16px !important;
  background-size: cover !important;
}

@media only screen and (max-width: 480px) {
  .invalid-fields {
    font-size: 12px;
    color: red;
    width: 345px !important;
  }
}

.public-DraftStyleDefault-block {
  text-align: inherit !important; /* Ensure it follows inline styles */
}

.public-DraftStyleDefault-ltr {
  text-align: left;
}

.public-DraftStyleDefault-center {
  text-align: center;
}

.public-DraftStyleDefault-right {
  text-align: right;
}

.public-DraftStyleDefault-justify {
  text-align: justify;
}

.bi-copy {
  padding-right: 10px;
}
